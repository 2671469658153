<template>
    <v-app-bar :elevation="2">
        <template v-slot:prepend>
            <v-img width="100" :aspect-ratio="1" :src="`https://amoikiss.com/Img/` + Game + `_logo.png`"></v-img>
        </template>

        <v-app-bar-title>{{ Game }}</v-app-bar-title>
    </v-app-bar>

    <v-container>
        <!-- 余额显示卡片 -->
        <v-card class="balance-card mb-4" color="error" max-width="300">
            <v-card-text class="pa-2">
                <div class="d-flex justify-space-between align-center">
                    <div>
                        <div class="text-caption text-white">{{ Name }}</div>
                        <div class="text-h6 text-white">AUD {{ GameBalance }}</div>
                    </div>
                    <div class="d-flex">
                        <v-btn icon="mdi-refresh" size="small" variant="text" color="white"
                            @click="CheckBalance"></v-btn>
                        <v-btn icon="mdi-chevron-down" size="small" variant="text" color="white"
                            @click="toggleMoreInfo"></v-btn>
                    </div>
                </div>
                <div class="text-caption text-white text-center" style="cursor: pointer" @click="toggleMoreInfo">
                    Click More
                </div>
            </v-card-text>

            <!-- 展开的更多信息 -->
            <v-expand-transition>
                <div v-if="showMoreInfo">
                    <v-divider></v-divider>
                    <v-card-text class="pa-2 text-white">
                        <div class="d-flex align-center mb-2">
                            <v-icon icon="mdi-cash-multiple" color="white" class="mr-2"></v-icon>
                            <span>提现限额: {{ MinWithdrawal }} - {{ MaxWithdrawal }}</span>
                        </div>
                        <v-btn color="white" variant="outlined" block @click="clearData" class="mt-2">
                            清空数据
                        </v-btn>
                    </v-card-text>
                </div>
            </v-expand-transition>
        </v-card>

        <v-text-field v-model="searchKeyword" label="Search Games" class="mx-4 mb-4 MyCard"></v-text-field>
        <!-- <span @click="navigateToLogin('vsgoldenkitty', 'BigPot')">bigpot</span> -->
        <!-- 游戏列表 -->
        <v-row>
            <v-col v-for="game in filteredGames" :key="game.id" class="d-flex child-flex" cols="3" md="2">
                <v-card style="width: 100%;" color="transparent" flat>
                    <v-img :src="game.ImageUrl" aspect-ratio="1" :alt="game.GameName" cover
                        @click="navigateToLogin(game.GameCode, Game)">
                        <template v-slot:placeholder>
                            <v-row class="" align="center" justify="center" no-gutters>
                                <v-progress-circular indeterminate color="grey-lighten-5"></v-progress-circular>
                            </v-row>
                        </template>

                    </v-img>
                    <p class="text-center MyCard">
                        {{ game.GameName }}
                    </p>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

    <!-- <v-card>
        <v-tabs v-model="tab" bg-color="deep-purple-accent-4" centered stacked grow>
            <v-tab value="tab-1" @click="fetchGames('EpicWin')">
                <v-img width="150" :aspect-ratio="1" src="https://amoikiss.com/Img/EpicWin_logo.png"></v-img>
            </v-tab>

            <v-tab value="tab-2" @click="fetchGames('BigPot')">
                <v-img width="150" :aspect-ratio="1" src="https://amoikiss.com/Img/Bigpot_logo.png"></v-img>
            </v-tab>
        </v-tabs>
    </v-card> -->
    <br>

    <v-overlay :model-value="overlay" class="align-center justify-center">
        <v-progress-circular color="primary" indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="AcPs" min-width="400" persistent>
        <v-card class="mx-auto" elevation="1">
            <v-card-title class="py-5 font-weight-black">Login</v-card-title>

            <v-card-text>
                Please check telegram your Game Password!
            </v-card-text>

            <v-card-text>

                <v-text-field v-model="AC" type="text" label="Account" variant="outlined" single-line></v-text-field>

                <v-text-field v-model="PS" type="password" label="Password" variant="outlined"
                    single-line></v-text-field>
                <v-btn class="text-none mb-4" :loading="LoginLoading" color="indigo-darken-3" size="x-large"
                    variant="flat" block @click="LoginGame()">
                    Login
                </v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';
import { useRoute } from 'vue-router'
import Cookies from 'js-cookie';
export default {
    data() {
        return {
            API: "https://api.amoikiss.com/",
            AC: Cookies.get('AC'),
            PS: Cookies.get('PS'),
            Name: Cookies.get('Name'),
            GameBalance: 0,
            AcPs: false,
            Game: 'EpicWin',
            LoginLoading: false,
            overlay: false,
            games: [],          // 保存原始游戏数据的数组
            searchKeyword: '',  // 搜索关键字
            MinWithdrawal: '0.00',
            MaxWithdrawal: '0.00',
            showMenu: false, // 新增控制菜单显示的数据
            showMoreInfo: false, // 新增控制更多信息显示的数据
        };
    },

    mounted() {
        const route = useRoute()
        // const isEpicWin = route.path === '/EpicWin'
        const gamePath = route.path.replace(/^\/+/, '') // 去除开头的 '/'

        this.Game = gamePath

        const queryParams = new URLSearchParams(window.location.search);
        const sParam = queryParams.get('s');
        if (sParam !== null) {

            if (sParam !== null) {
                // this.APIData.SecrtKey = sParam;
                Cookies.set('AC', sParam);
                window.location.assign(route.path);
                // window.location.assign(this.MY_URL);
            }
        } else {
            if (this.CheckLogin()) {
                this.fetchGames(this.Game);
                this.CheckBalance();
            }
        }



        // 调用获取游戏数据的方法

    },

    computed: {
        // 根据搜索关键字过滤游戏列表
        filteredGames() {
            return this.games.filter(game => game.GameName.toLowerCase().includes(this.searchKeyword.toLowerCase()));
        },
    },

    methods: {
        getColumns() {
            // 這裡你可以根據窗口大小返回不同的列數
            // 例如，如果寬度小於某個值， 3，否則返回 2
            return window.innerWidth < 600 ? 3 : 2;
        },
        CheckLogin() {
            if (!this.AC || !this.PS) {
                this.AcPs = true;
                return false
            } else {
                return true
            }

        },
        LoginGame() {
            this.LoginLoading = true;
            const formData = new FormData();

            formData.append('AC', this.AC);
            formData.append('PS', this.PS);

            axios.post(this.API + '/Game/PlayerLogin.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.LoginLoading = false;
                if (response.data.Status) {
                    this.AcPs = false;
                    Cookies.set('PS', this.PS);
                    Cookies.set('Name', response.data.Name);
                    this.Name = response.data.Name;
                    this.fetchGames(this.Game);
                    this.CheckBalance();
                } else {
                    this.PS = "";
                    alert(response.data.message);
                }
            });


        },
        CheckBalance() {
            if (this.CheckLogin()) {
                const formData = new FormData();
                formData.append('AC', this.AC);
                formData.append('Game', this.Game);

                axios.post(this.API + '/Game/PlayerGameData.php', formData, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                }).then(response => {
                    this.GameBalance = response.data.Balance;
                    this.MinWithdrawal = response.data.MinWithdrawal;
                    this.MaxWithdrawal = response.data.MaxWithdrawal;
                });
            }
        },
        async fetchGames(gameListUrl) {
            this.Game = gameListUrl;
            try {
                this.overlay = true;
                const response = await axios.get(this.API + '/Game/GetGameList.php?GameURL=' + gameListUrl);
                this.games = response.data.map(game => ({ ...game, loading: false }));

            } catch (error) {
                console.error('Error fetching games:', error);
            }
            finally {
                // 无论请求成功或失败，都会在最后将 loading 设置为 false，隐藏加载状态
                this.overlay = false;
            }
        },

        navigateToLogin(GameCode, Path) {
            if (!this.CheckLogin()) {
                alert("Login Error!");
                return
            }

            this.overlay = true;
            const formData = new FormData();

            formData.append('AC', this.AC);
            formData.append('Path', Path);
            formData.append('GameCode', GameCode);
            axios.post(this.API + '/Game/PlayGame.php', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
            }).then(response => {
                this.overlay = false;
                if (response.data.Status) {
                    window.location.href = response.data.Url;
                } else {
                    alert(response.data.Message);
                }
            });
        },
        toggleMoreInfo() {
            this.showMoreInfo = !this.showMoreInfo;
        },
        clearData() {
            // 清除所有 Cookie
            // Cookies.remove('AC');
            Cookies.remove('PS');
            Cookies.remove('Name');
            // 重置数据
            this.AC = null;
            this.PS = null;
            this.Name = null;
            this.GameBalance = 0;
            this.MinWithdrawal = '0.00';
            this.MaxWithdrawal = '0.00';
            // 关闭菜单
            this.showMenu = false;
            // 显示登录对话框
            this.AcPs = true;
        },
    },
};
</script>

<style>
.MyCard {
    color: aliceblue;
    /* font-size:100%; */
}

.GameList {
    padding-left: 10%;
    padding-right: 10%;
}

@media only screen and (max-width: 600px) {
    .GameList {
        padding-left: 0;
        padding-right: 0;
    }
}

.custom-no-gutters {
    padding: 0 !important;
}

.v-col-md-2 {
    padding: 5px !important;
}

.balance-card {
    border-radius: 8px;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
}
</style>